import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from '../outer-services/storage.service';

@Injectable()
export class CheckAuthTokenService {
    private jwtHelper: JwtHelperService = new JwtHelperService();


    constructor(private storageService: StorageService) { }

    isTokenExpired(): boolean {
        const authVal = this.storageService.sessionStorageGet('auth_key');
        return this.jwtHelper.isTokenExpired(authVal);
    }
}