/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, AfterViewInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Action, select } from '@ngrx/store';
import { of } from 'rxjs';

/**Services **/
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import {
  Occ_EmpOutlook_Wages_State,
  Occ_Settings_Tab_Text_State,
  Occ_Buttons_Reducer_Text_State,
  Occ_Detail_Employment_Outlook_State,
} from '../../occ-state-management/occ-state/occ-state';

import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import html2pdf from 'html2pdf.js';
import { delay, takeWhile } from 'rxjs/operators';
import { IFindJob } from '../../models/occ-search';

@Component({
  selector: 'emp-and-outlook',
  templateUrl: './emp-outlook-layout.html',
})
export class EmpOutlookComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('empOutLookPrintPage', { static: true })
  empOutLookPrintPage: ElementRef;
  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  displayValue = 1; /** Based on this we will apply disable property to the list */
  occCareerText; /** Is a variable that is used to store data coming from reducer */
  loggedInState;
  selectedStates; /** Contains user selected state, by default US */
  duplicateState; /** clone variable for selected states, used when displaying states  */
  settingsText; /** Is a variable that is used to store data coming from reducer */
  settingsTextTab;
  langVal; /** To get the value of language */
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  loc_btn_color = ['#a22903', '#005787', '#1b621e', '#014941', '#770c84'];
  compAlive = true;
  occname;
  occId;
  printPage = false;
  restrictedAccountConstant; /**Variable used to store restrictedaccounttype  */
  accountTypeJwt; /**Variable used to store accounttype from JWT */
  restrictedAccountType: boolean;

  states: Array<string> = ['US', StorageService.staticSessionStorageGet('stateAbr')];
  stateAbbr: string = StorageService.staticSessionStorageGet('stateAbr');
  findJobs: IFindJob[] = [];
  hasMoreItems: boolean = false;
  constructor(
    private router: Router,
    private utils: Utilities,
    private storageService: StorageService,
    private occstore: Store<Occ_Buttons_Reducer_Text_State>,
    private occDispatch: OccDispatchService,
    private dispatchStore: Store<Action>,
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    @Inject('RESTRICTED_ACCOUNT_TYPE') public restrictedAccount: string,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string
  ) {
    this.accountTypeJwt = this.utils.getDecodedAccessToken().accountType;
    this.restrictedAccountConstant = restrictedAccount;
    if (this.accountTypeJwt !== this.restrictedAccountConstant) {
      this.restrictedAccountType = true;
    } else {
      this.restrictedAccountType = false;
    }
    this.loggedInState = this.storageService.sessionStorageGet('stateAbr');
    this.selectedStates = ['US', this.loggedInState];
    this.duplicateState = ['US', this.loggedInState];
    this.occDispatch
      .careerOutLookListen()
      .pipe(takeWhile(() => this.compAlive))
      .subscribe(() => {
        // console.log('AGA called ---');
        this.utils.showLoading();
        this.careerPrint();
      });
    /** Get user selected languge */
    this.langVal = this.storageService.sessionStorageGet('langset');
    /** Check whether user selected states while reloading,
     * if he selected place that states in selected variables,
     * if not default values are inserted
     */
    if (this.storageService.sessionStorageGet('outlookStates')) {
      const arr = this.storageService.sessionStorageGet('outlookStates').split(',');
      this.duplicateState = arr;
      this.selectedStates = arr;
    } else {
      this.selectedStates = ['US', this.loggedInState];
      this.duplicateState = ['US', this.loggedInState];
    }
    // Get data from reducer to display buttons text
    this.occstore
      .pipe(
        select('Occ_Buttons_Reducer_Text'),
        takeWhile(() => this.compAlive)
      )
      .subscribe((v) => {
        this.occCareerText = v;
      });
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSetstore
      .pipe(
        select('Occ_Settings_Tab_Text'),
        takeWhile(() => this.compAlive)
      )
      .subscribe((v) => {
        this.settingsText = v;
        if (this.settingsText.Occ_Settings_Tab_Text.tabs) {
          this.settingsTextTab =
            this.settingsText.Occ_Settings_Tab_Text.tabs.find(
              (obj) => obj['tabId'] === 3
            );
        }
      });
    // Get data from reducer to display in cards
    this.OCCPageStateStore.pipe(
      select('Career_Detail_Employment_Outlook')
    ).subscribe((v) => {
      this.occCareerStore = v;
      if (this.occCareerStore && this.occCareerStore.FindJobs) {
        this.findJobs = [];
        for (let i = 0; i < this.occCareerStore.FindJobs.length; i++) {
          //first two elements
          if (i <= 1) {
            let _findJob: IFindJob = {
              type: this.occCareerStore.FindJobs[i].type,
              text: this.occCareerStore.FindJobs[i].text,
              isMoJobs: false
            };
            this.hasMoreItems = _findJob.text.length > 1;
            this.findJobs.push(_findJob);
          }
          else {
            let _findJob: IFindJob = {
              type: this.occCareerStore.FindJobs[i].type,
              text: this.occCareerStore.FindJobs[i].text,
              isMoJobs: true
            };
            this.hasMoreItems = _findJob.text.length > 1;
            this.findJobs.push(_findJob);
          }
        }
      }
      if (
        this.occCareerStore &&
        this.occCareerStore.OutlookRatings &&
        this.occCareerStore.OutlookRatings.states
      ) {
        of('delay Observable time')
          .pipe(delay(1200))
          .subscribe(() => {
            this.getPdfData();
          });
      }
    });
  }
  getPdfData() {
    let count = 0;
    this.occCareerStore.OutlookRatings.states.map((val) => {
      if (val.regions.length > 0) {
        val.regions.map((val2) => {
          val2.inx = count = count + 1;
        });
      }
    });
    //sort states alphabetically
    this.occCareerStore.OutlookRatings.states.sort(function (a, b) {
      return a.state.toLowerCase().localeCompare(b.state.toLowerCase());
    });
    //United States needs to always display at bottom of Employment and Outlook tables
    const UsState = 'United States'.toLowerCase();
    const USData = this.occCareerStore.OutlookRatings.states.find(
      (state) => state.state.toLowerCase() === UsState
    );
    const USIndex = this.occCareerStore.OutlookRatings.states.findIndex(
      (state) => state.state.toLowerCase() === UsState
    );
    if (this.occCareerStore.OutlookRatings.states.length > 0 && USIndex >= 0) {
      this.occCareerStore.OutlookRatings.states.splice(USIndex, 1);
      this.occCareerStore.OutlookRatings.states.splice(
        this.occCareerStore.OutlookRatings.states.length,
        0,
        USData
      );
    }
  }
  ngAfterViewInit() {
    let states;
    this.selectedStates = this.duplicateState;
    if (this.duplicateState[0] !== "''") {
      states = this.duplicateState;
      //making sure that 5 states are selected max.
      states[1] === 'IC'
        ? this.duplicateState.splice(1, 1) && (states = ['US'])
        : (states = this.duplicateState);
    } else {
      /*Here below we have used this else, if there arises a case
        where we donot want by default 'US' to load its details.
        Then the else case will be executed. Here the expected value
        is a string so, if we use '' or "" within the array then we get error,
        as the expected is a string format if we send undefined(''or"")
        the app crashes to avoid such a crash we have considered "''"   */
      states = ["''"];
    }
    if (!this.occId || this.occId.length === 0) {
      this.utils.log('emp-outlook-component.ts ngAfterViewInit GET_DETAIL_EMPLOYMENT_OUTLOOK', 'debug').subscribe();
    }
    this.storageService.sessionStorageSet('outlookStates', states);
    this.dispatchStore.dispatch({
      type: 'GET_DETAIL_EMPLOYMENT_OUTLOOK',
      payload: {
        methodVal: 'GET',
        module_Name: 'Occ/v1/',
        path_params: ['occ', parseInt(this.occId), 'accountID'],
        query_params: {
          sections:
            'MajorEmployers,OutlookInfo,OutlookRatings,TopOpeningLocations,FindJobs',
          states: states,
          lang: this.storageService.sessionStorageGet('langset'),
        },
        body_Params: {},
        endUrlVal: 'pages',
      },
    });
  }
  ngOnInit() {
    const val = this.storageService.sessionStorageGet('Reducer_OccText')
      ? JSON.parse(this.storageService.sessionStorageGet('Reducer_OccText'))
      : undefined;
    if (
      val &&
      val.Occ_Buttons_Reducer_Text &&
      val.Occ_Buttons_Reducer_Text.common
    ) {
      this.occCareerText = val;
    }
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compAlive))
      .subscribe((params) => {
        this.occname = params['occname'];
        this.occId = params['occid'];
      });
  }
  async careerPrint() {
    const element = this.empOutLookPrintPage.nativeElement;
    const opt = {
      filename: 'Career - Employment & Outlook.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      margin: [0.1, 0, 0.2, 0],
      jsPDF: { compress: true },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  /** this ngOnDestroy() function is called after Component destory */

  ngOnDestroy() {
    this.compAlive = false;
  }
  checkopen() {
    // Works when we click on selectStates button
    this.duplicateState = [];
    for (let i = 0; i < this.selectedStates.length; i++) {
      this.duplicateState.push(this.selectedStates[i]);
    }
    this.displayValue = this.selectedStates.length;
  }
  showStates() {
    // Works when we click on Apply button
    this.utils.showLoading();
    let states;
    this.selectedStates = this.duplicateState;
    if (this.duplicateState[0] != "''") {
      states = this.duplicateState;
    } else {
      /*Here the expected value
      is a string so, if there is no default state and we select no state
      then we are going to pass undefined or null so if we use '' or ""
      within the array then we get error,
      as the expected is a string format if we send undefined(''or"")
      the app crashes to avoid such a crash we have considered "''"   */
      states = ["''"];
    }
    this.storageService.sessionStorageSet('outlookStates', states);
    const val = this.storageService.sessionStorageGet('Reducer_OccText')
      ? JSON.parse(this.storageService.sessionStorageGet('Reducer_OccText'))
      : undefined;
    if (
      val &&
      val.Occ_Buttons_Reducer_Text &&
      val.Occ_Buttons_Reducer_Text.common
    ) {
      this.occCareerText = val;
    }
    const asmtEndurl = this.restrictedAccountType
      ? 'occ/standard'
      : 'occ/standard/default';

    this.dispatchStore.dispatch({
      type: 'GET_OCC_SETTING_TABS_TEXT',
      payload: {
        methodVal: 'GET',
        module_Name: 'Settings/v1/',
        path_params: this.restrictedAccountType ? ['accountID'] : [],
        query_params: { lang: this.langVal },
        body_Params: {},
        endUrlVal: asmtEndurl,
        name: 'careers',
      },
    });
  }
  count(val, e) {
    //Works when we click on check box
    if (this.duplicateState[0] == "''") {
      this.duplicateState = [];
    }
    if (e.which == 13 || e == 'click') {
      if (this.duplicateState.indexOf(val) == -1) {
        this.duplicateState.push(val);
      } else if (this.duplicateState.indexOf(val) != -1) {
        this.duplicateState.splice(this.duplicateState.indexOf(val), 1);
      }
      this.displayValue = this.duplicateState.length;
      if (this.displayValue == 0) {
        this.duplicateState = ["''"];
      }
    }
  }
  cancelStates() {
    //Works when we click on cancel button
    this.duplicateState = this.selectedStates;
    this.displayValue = this.selectedStates.length;
  }
  resetStates() {
    //Works when we click on reset button
    this.displayValue = 1;
    //here we have taken 5 in splice because we want to restrict selection of states upto 5
    this.loggedInState === 'IC'
      ? this.duplicateState.splice(0, 5, 'US')
      : this.duplicateState.splice(0, 5, 'US', this.loggedInState);
  }
}
