import { FrameworkConfigState } from '../../../../../state-management/state/main-state';
/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime, take, takeWhile } from 'rxjs/operators';
/**Services **/
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import { CareersJrSnackBar } from '../../careersJr-modal/careersJr-modal-component';
import { CareersJrApiCallClass } from '../../careersJr-constants/model';
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import { CareersJrApiCallService } from '../../careersJr-services/careersJr-apicalls.service';
import {
  CareersJr_Settings_Tab_Text_State,
  CareersJr_Index_Reducer_Text_State,
  CareersJr_Buttons_Reducer_Text_State,
  CareersJr_EmpOutlook_Wages_State,
} from '../../careersJr-state-management/careersJr-state/careersJr-state';
import { CareersJrCommonMethodsService } from '../../careersJr-services/careersJr-common-methods.service';
import { EventDispatchService } from '../../../../../shared/outer-services/event-dispatch.service';

@Component({
  selector: 'careersJr-detail-header',
  templateUrl: './careersJr-header-layout.html',
})
export class CareersJrHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('videoplayer') videoplayer: ElementRef;
  @ViewChild('occCarrerFocus', { static: true })
  occCarrerFocusElement: ElementRef;
  showCareerColorUp = -1; /** To show thumbs up or down */
  filter = false; /** Became true or false when user clicks on Fav icon */
  value = ''; /** To display name of the tab in dropdown for mobile screen */
  states = [
    'US',
    StorageService.staticSessionStorageGet('stateAbr'),
  ]; /** Contains the states that user belongs to and by default the 'US' */
  indexOccId = []; /** Contains id values */
  indexTitleName = []; /** Contain titles to display in compare popup */
  accId = ''; /** Contains account id value */
  occID = ''; /** Contains current occId value */
  occName; /** Contain current occName */
  occTxtVal = {}; /** JSON obj that contains id and name */
  isSaveid =
    []; /** If user save data in compare popup, it'll stored in this variable */
  activeVal = 0; /** Use to tell which tab we have to display */
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  hideVal = 0;
  settingsText; /** Is a variable that is used to store data coming from reducer */
  occCareerText; /** Is a variable that is used to store data coming from reducer */
  eventSub = new Subscription();
  langChange = false;
  occclusinx = '';
  occIndexReducerSub = new Subscription();
  outereventsubscription = new Subscription();
  backAssessmentValue = false; /** To show back to assesment button  */
  langVal; /** Gets user selected language */
  public snackbar = new CareersJrSnackBar();
  valueForloop = false;
  subscription2 = new Subscription();
  frameworkObj;
  logo;
  restrictedAccountConstant; /**Variable used to store restrictedaccounttype  */
  accountTypeJwt; /**Variable used to store accounttype from JWT */
  restrictedAccountType: boolean;
  compActive = true;
  occAlive = true;
  /**show current tab content only */
  activeTab: number = 0;
  constructor(
    private router: Router,
    private careersJrstore: Store<CareersJr_Buttons_Reducer_Text_State>,
    private activatedRoute: ActivatedRoute,
    private utils: Utilities,
    private storageService: StorageService,
    private apiJson: CareersJrApiCallClass,
    private careersJrSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private dispatchStore: Store<Action>,
    private careersJrPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    private careersJrApiCall: CareersJrApiCallService,
    private careersJrIndexstore: Store<CareersJr_Index_Reducer_Text_State>,
    private careersJrCommonService: CareersJrCommonMethodsService,
    private eventService: EventDispatchService,
    private careersJrDispatch: CareersJrDispatchService,
    private Frameworkstore: Store<FrameworkConfigState>,
    @Inject('RESTRICTED_ACCOUNT_TYPE') public restrictedAccount: string,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('LANDING_IMAGE_URL') public LandingImages: string
  ) {
    this.eventService
      .listen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        if (e.type == 'languageChanged') {
          this.langVal = this.storageService.sessionStorageGet('langset');
          const Arr = this.router.url.split('?');
          const routArr = Arr[0].split('/');
          this.hideVal = 0;
          this.langChange = true;
          this.ngOnInit();
        }
      });

    this.careersJrDispatch
      .listen()
      .pipe(takeWhile(() => this.occAlive))
      .subscribe((e) => {
        if (e.type === 'callWagePage') {
          /** When user clicks on 'wages' card in at-a-glance screen this subscription detected*/
          this.checkValues(2);
        } else if (e.type === 'job_Setting' || e.type === 'job_Task') {
          /** When user clicks on 'job task' or 'work setting' card in at-a-glance screen this subscription detected*/
          this.checkValues(1);
        } else if (e.type === 'callOutlookPage') {
          /** When user clicks on 'Employment oppertunities' card in at-a-glance screen this subscription detected*/
          this.checkValues(3);
        } else if (e.type === 'calleduPage') {
          /** When user clicks on 'education and training' card in at-a-glance screen this subscription detected*/
          this.checkValues(4);
        }
      });

    this.accountTypeJwt = this.utils.getDecodedAccessToken().accountType;
    this.restrictedAccountConstant = restrictedAccount;
    if (this.accountTypeJwt !== this.restrictedAccountConstant) {
      this.restrictedAccountType = true;
    } else {
      this.restrictedAccountType = false;
    }
    this.storageService.sessionStorageSet('activeSortId', 'fileOcc');
    if (this.storageService.sessionStorageGet('locationModule')) {
      const occLtidata = JSON.parse(
        this.storageService.sessionStorageGet('locationModule')
      ).componentId.split('|');
      if (occLtidata[2]) {
        this.activeVal = parseInt(occLtidata[2]) - 1;
        this.storageService.sessionStorageRemove('locationModule');
      }
    }

    /** Get user selected languge */
    this.langVal = this.storageService.sessionStorageGet('langset');
    this.dispatchStore.dispatch({ type: 'RESET_CAREERSJR_SETTING_TABS_TEXT' });
    this.dispatchStore.dispatch({ type: 'RESET_OCC_PAGE' });
    this.storageService.sessionStorageRemove('outlookStates');
    this.storageService.sessionStorageRemove('wagesStates');

    if (
      !this.storageService.sessionStorageGet('CareersJrIndexReducerText') ||
      !this.storageService.sessionStorageGet('CareersJr_Reducer_OccText')
    ) {
      this.careersJrCommonService.OccIndexDispatch();
    }
    this.Frameworkstore.select('config')
      .pipe(take(1))
      .subscribe((res) => {
        if (res.config) {
          this.frameworkObj = res.config;
        }
      });
    const ref = this;
    function convertImgToDataURLviaCanvas(url, callback, outputFormat) {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        try {
          dataURL = canvas.toDataURL(outputFormat);
          callback(dataURL);
          canvas = null;
        } catch (e) {
        } finally {
          dataURL =
            'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
          callback(dataURL);
          canvas = null;
        }
      };
      img.src = url;
    }
    convertImgToDataURLviaCanvas(
      ref.frameworkObj.Result.user.logo,
      function (base64Img) {
        ref.logo = base64Img;
      },
      'base64'
    );

    this.activatedRoute.queryParams.subscribe((params) => {
      // Get occId from parameters
      this.occID = params['occid'];
      this.occclusinx = params['inx'];

      // Get key value to nameTxt using occID
    });
    //Setting default state
    this.storageService.sessionStorageSet('states', 'US');
    // Get data from reducer to display buttons text

    this.careersJrstore
      .select('CareersJr_Buttons_Reducer_Text')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((occButtons) => {
        this.occCareerText = occButtons;
      });

    if (this.storageService.sessionStorageGet('personalQua') === 'yes') {
      this.activeVal = 5;
    }
    // Get data from reducer to show tab names, user rating etc.,
    this.careersJrSetstore
      .select('CareersJr_Settings_Tab_Text')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((occSettingsText) => {
        this.settingsText = occSettingsText;
        if (
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != null &&
          this.settingsText.CareersJr_Settings_Tab_Text.tabs !== undefined
        ) {
          this.value =
            this.settingsText.CareersJr_Settings_Tab_Text.tabs[0].title;
          const ref = this;
          const dropDownValue =
            this.settingsText.CareersJr_Settings_Tab_Text.tabs.find(
              (x: any) => x.tabId === this.activeVal
            );
          ref.dropDownVal(dropDownValue.title, dropDownValue.tabId);
          ref.utils.hideLoading();
        }
      });
    this.outereventsubscription = this.eventService
      .listenStaticContent()
      .subscribe((e) => {
        if (this.occCarrerFocusElement) {
          this.occCarrerFocusElement.nativeElement.setAttribute(
            'tabindex',
            '0'
          );
          this.occCarrerFocusElement.nativeElement.focus();
        }
      });

    // Get data from reducer to show image and vedio
    this.occCareerStore = this.careersJrPageStateStore.select(
      'CareersJr_Detail_AtGlance_Selector'
    );
    this.occIndexReducerSub = this.careersJrIndexstore
      .select('CareersJr_Index_Reducer_Text')
      .pipe(debounceTime(100))
      .subscribe((occIndex) => {
        if (
          occIndex.CareersJr_Index_Reducer_Text &&
          this.valueForloop === false &&
          occIndex.CareersJr_Index_Reducer_Text.occList
        ) {
          this.valueForloop = true;
          this.savedCareerNames();
        }
      });
    //below condition is for checking back to assessment button to display
    if (this.storageService.sessionStorageGet('inTab')) {
      if (
        this.storageService.sessionStorageGet('inTab') === 'Assess' ||
        this.storageService.sessionStorageGet('inTab') === 'cciAssess' ||
        this.storageService.sessionStorageGet('inTab') === 'osAssess'
      ) {
        this.backAssessmentValue = true;
      } else {
        this.backAssessmentValue = false;
      }
    }
    this.accId = 'accountID';
    this.eventSub = this.careersJrDispatch.listen().subscribe((evnt) => {
      if (evnt.type === 'relatedDispatch') {
        this.activeVal = 0;
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    /** When user click on link that is in related career to navegate
     * to another career we are subscribing an event
     */
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.occID = params['occid'];
        this.occName = params['occname'];

        /**load settings from api, remove this call from
         * getCareer() method
         */
        const asmtEndurl = this.restrictedAccountType
          ? 'OccJr/standard'
          : 'OccJr/standard/default';
        this.dispatchStore.dispatch({
          type: 'GET_CAREERSJR_SETTING_TABS_TEXT',
          payload: {
            methodVal: 'GET',
            module_Name: 'Settings/v1/',
            path_params: this.restrictedAccountType ? ['accountID'] : [],
            query_params: { lang: this.langVal },
            body_Params: {},
            endUrlVal: asmtEndurl,
            name: 'careers',
          },
        });

        // this.savedCareerNames();
        this.getCareer();
      });
    this.storageService.sessionStorageSet('occIDval', this.occID);
    this.utils.showLoading();

    this.careerHeader();

    setTimeout(() => {
      this.savedCareerNames();
    }, 800);
  }

  videostop() {
    this.videoplayer.nativeElement.load();
    this.videoplayer.nativeElement.pause();
  }
  savedCareerNames() {
    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJrIndexReducerText'));
    let value = true;
    if (val && val.CareersJr_Index_Reducer_Text) {
      val.CareersJr_Index_Reducer_Text.occList.forEach((list) => {
        this.occTxtVal['occ_' + list.occID] = list.title;

        if (list.occID === parseInt(this.occID) && value === true) {
          this.occName = list.title;
          value = false;
          this.getValues();
        }
      });
    }
  }
  //The method checks tabId and open respective tabs
  checkValues(number: number) {
    if (this.settingsText.CareersJr_Settings_Tab_Text.tabs) {
      const ref = this;
      this.settingsText.CareersJr_Settings_Tab_Text.tabs.forEach(
        function (obj, inx) {
          if (obj['tabId'] === number) {
            ref.dropDownVal(obj['title'], inx);
          }
        }.bind(this)
      );
    }
  }
  getValues() {
    if (this.occID !== '') {
      this.indexOccId[0] = parseInt(this.occID);
      this.indexTitleName[0] = this.occName;
    }
    //calling getCareer method for getting text from api
    this.getCareer();
  }
  /** this ngOnDestroy() function is call after Component destory */
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.compActive = false;
    this.eventSub.unsubscribe();
    this.occIndexReducerSub.unsubscribe();
    this.outereventsubscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.storageService.removeSessionIndexes(true);
  }
  getUnFill(thumbVal) {
    this.utils.showLoading();
    //this call is to fill and unfill the thumbs up and down icon
    if (thumbVal === 'up' && this.showCareerColorUp !== 1) {
      this.showCareerColorUp = 1;
    } else if (thumbVal === 'down' && this.showCareerColorUp !== 0) {
      this.showCareerColorUp = 0;
    } else {
      this.showCareerColorUp = -1;
    }
    this.resultPost();
  }
  compareOccupations() {
    //used to navigate to compare screen
    let routeUrl = '../careerJrcompare';
    const routeVal = this.storageService.sessionStorageGet('prevRoute');
    if (this.storageService.sessionStorageGet('prevRoute')) {
      if (routeVal.includes('cciJr') || routeVal.includes('cciQuick')) {
        routeUrl = '../../' + 'careers-details' + '/compare';
      } else {
        routeUrl = '../../' + this.storageService.sessionStorageGet('prevRoute') + '/compare';
      }
    }
    try {
      this.router.navigate([routeUrl], {
        relativeTo: this.activatedRoute,
        queryParams: {
          occId: this.indexOccId[0] + '&' + this.indexOccId[1],
          occName: this.indexTitleName[0] + '&' + this.indexTitleName[1],
        },
      });
    } catch (e) {
      console.log('error--->' + e.message);
    }
  }

  //Gets executed when related career tab was clicked
  relate(eve) {
    this.occID = eve[0];
    this.accId = 'accountID';
    this.getCareer();
  }

  getCareer() {
    // if (this.langChange === false) {

    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJr_Reducer_OccText'));
    if (
      val &&
      val.careersJr_Buttons_Reducer_Text &&
      val.careersJr_Buttons_Reducer_Text.common
    ) {
      this.occCareerText = val;
    }
    this.getRating();
  }

  savePost() {
    //to post the favorites data
    this.utils.showLoading();
    this.apiJson.method = 'POST';
    this.apiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
    this.apiJson.moduleName = 'OccJr/v1/';
    const saveResult = {
      input_data: [
        {
          param_type: 'path',
          params: ['accountID', 'favorites', this.occID],
        },
        {
          param_type: 'query',
          params: { lang: this.langVal },
        },
      ],
    };
    const user = JSON.stringify(saveResult);
    this.apiJson.endUrl = 'users';
    this.apiJson.data = user;
    this.careersJrApiCall
      .callApi([this.apiJson])
      .pipe(takeWhile(() => this.compActive))
      .subscribe(
        (res) => {
          this.utils.hideLoading();
          /*** Event dispatch for Plans Status reflection***/
          const evnt = document.createEvent('CustomEvent');
          evnt.initEvent('PlanCompStatus', true, true);
          this.eventService.dispatch(evnt);
          this.snackbar.myFunction('Successfully added to favorites.');
        },
        (e) =>
          this.utils.handleError(
            `careersJr-header-component.ts savePost: ${e.message}`
          )
      );
  }
  getRating() {
    // to get the data to show what user selected between thumbs up and down
    this.apiJson.method = 'GET';
    this.apiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
    this.apiJson.moduleName = 'OccJr/v1/';
    const getThumbsResult = {
      input_data: [
        {
          param_type: 'path',
          params: ['accountID', 'ratings', this.occID],
        },
        {
          param_type: 'query',
          params: { lang: this.langVal },
        },
      ],
    };
    const user = JSON.stringify(getThumbsResult);
    this.apiJson.endUrl = 'users';
    this.apiJson.data = user;
    this.careersJrApiCall
      .callApi([this.apiJson])
      .pipe(takeWhile(() => this.compActive))
      .subscribe(
        (res) => {
          if (res[0].Success + '' === 'true') {
            if (res[0].Result === 1) {
              this.showCareerColorUp = 1;
            } else if (res[0].Result === 0) {
              this.showCareerColorUp = 0;
            } else {
              this.showCareerColorUp = -1;
            }
          }
        },
        (e) =>
          this.utils.handleError(
            `careersJr-header-component.ts getRating: ${e.message}`
          )
      );
  }

  dropDownVal(name: string, num: number) {
    //for dropdown in mobile
    this.value = name;
    this.activeVal = num;
    this.activeTab = num;
    const evnt = document.createEvent('CustomEvent');
    evnt.initEvent(name, true, true);
    this.careersJrDispatch.dispatch(evnt);
  }
  careerHeader() {
    //data for header and At glance tab
    try {
      this.dispatchStore.dispatch({
        type: 'GET_CAREERJR_ATGLANCE',
        payload: {
          methodVal: 'GET',
          module_Name: 'OccJr/v1/',
          path_params: ['OccJr', this.occID, 'accountID'],
          query_params: {
            // sections: 'all',
            sections:
              'SalaryRating,EducationLevel,OpportunityRating,ShortDescription ,TopJobTasks,ClusterDefinition,HotJob,STEM,SelfEmployment,ParentCluster,EdLevelOnCard,TopWorkSetting, Image, Video,VideoWithText, WageLevels, TopOverview, TopPreparation, Preparation',
            states: this.states,
            lang: this.langVal,
          },
          body_Params: {},
          endUrlVal: 'pages',
        },
      });
    } catch (e) {
      console.log('error------>' + e.message);
    }
  }
  resultPost() {
    //post thumbs up and down to api
    setTimeout(
      function () {
        this.apiJson.method = 'POST';
        this.apiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
        this.apiJson.moduleName = 'OccJr/v1/';
        const starResult = {
          input_data: [
            {
              param_type: 'path',
              params: [
                'accountID',
                'ratings',
                this.occID,
                this.showCareerColorUp,
              ],
            },
            {
              param_type: 'query',
              params: { lang: this.langVal },
            },
          ],
        };
        const user = JSON.stringify(starResult);
        this.apiJson.endUrl = 'users';
        this.apiJson.data = user;
        this.careersJrApiCall
          .callApi([this.apiJson])
          .pipe(takeWhile(() => this.compActive))
          .subscribe(
            (res) => {
              if (res.Result + '' === 'true') {
                const val = JSON.parse(
                  this.storageService.sessionStorageGet('CareersJrIndexReducerText')
                );
                val.CareersJr_Index_Reducer_Text.occList.forEach((occ) => {
                  if (occ.occID === parseInt(this.occID, 10)) {
                    occ.rating = this.showCareerColorUp;
                  }
                });

                this.storageService.sessionStorageSet(
                  'CareersJrIndexReducerText',
                  JSON.stringify(val)
                );

                if (
                  this.storageService.sessionStorageGet('prevRoute') === 'occSort' &&
                  JSON.parse(this.storageService.sessionStorageGet('OSOnMyList'))
                ) {
                  const value = JSON.parse(
                    this.storageService.sessionStorageGet('OSOnMyList')
                  );
                  value.OsGetAllOccList.occList.forEach((occ) => {
                    if (occ.occID === parseInt(this.occID, 10)) {
                      occ.rating = this.showCareerColorUp;
                    }
                  });
                  this.storageService.sessionStorageSet('OSOnMyList', JSON.stringify(value));
                }
              }
              this.utils.hideLoading();
            },
            (e) =>
              this.utils.handleError(
                `careersJr-header-component.ts resultPost: ${e.message}`
              )
          );
      }.bind(this)
    );
  }

  checkCareer(id) {
    //this method is to remove occupation from popup

    let inx1 = -1;
    for (let i = 0; i < this.indexOccId.length; i++) {
      if (id === this.indexOccId[i]) {
        inx1 = i;
        this.isSaveid.push(id);
      }
    }
    this.indexOccId.splice(inx1, 1);
    this.indexTitleName.splice(inx1, 1);
  }
  careerList(check) {
    // navigate to occindex based on whether the occupation saved or not
    if (this.occclusinx === 'title') {
      this.storageService.sessionStorageSet('careerJrShow', '1');
    } else {
      this.storageService.sessionStorageSet('careerJrShow', '0');
    }
    this.router.navigate(['../careerJrIndex'], {
      relativeTo: this.activatedRoute,
      queryParams: { chk: check, occclusinx: this.occclusinx },
    });
  }
  cancleOccupation() {
    // when x in pop-up was clicked cancel the changes
    for (let i = 0; i < this.isSaveid.length; i++) {
      this.indexOccId.push(this.isSaveid[i]);
      const nameValue = this.occTxtVal['occ_' + this.isSaveid[i]];
      this.indexTitleName.push(nameValue);
    }
    this.isSaveid = [];
  }

  showAllCareer() {
    // when compare button was clicked the below code executed to display Name
    if (this.storageService.sessionStorageGet('savedValId') !== '') {
      const idValue = parseInt(this.storageService.sessionStorageGet('savedValId'));
      if (this.indexOccId.indexOf(idValue) === -1) {
        const nameValue = this.occTxtVal['occ_' + idValue];
        if (nameValue !== undefined) {
          this.indexTitleName.push(nameValue);
          this.indexOccId.push(idValue);
        }
      }
    }
  }
  saveOccupation() {
    this.isSaveid = [];
    // when save button in pop-up was clicked save the occupation to compare
    if (this.indexOccId.length === 1) {
      this.storageService.sessionStorageSet('savedValId', this.indexOccId[0]);
    }
    if (this.indexOccId.length === 0) {
      this.storageService.sessionStorageSet('savedValId', '');
    }
  }
  backAssessment() {
    // when back assessment data was clicked navigate to respective page
    this.storageService.sessionStorageSet('fromCareers', 'yes');
    if (
      this.storageService.sessionStorageGet('inTab') !== 'cciAssess' &&
      this.storageService.sessionStorageGet('inTab') !== 'osAssess'
    ) {
      this.router.navigate(
        ['../../' + this.storageService.sessionStorageGet('prevRoute') + '/occlist'],
        { relativeTo: this.activatedRoute }
      );
    } else {
      this.router.navigate(
        ['../../' + this.storageService.sessionStorageGet('prevRoute') + '/result'],
        { relativeTo: this.activatedRoute }
      );
    }
  }
  careersPrint() {
    const evnt = document.createEvent('CustomEvent');
    switch (this.activeVal) {
      case 0: {
        this.careersJrDispatch.careerAtglanceDispatch(evnt);
        break;
      }
      case 1: {
        this.careersJrDispatch.careerJobDescriptionDispatch(evnt);
        break;
      }
      case 2: {
        this.careersJrDispatch.careerWagesDispatch(evnt);
        break;
      }
      case 3: {
        this.careersJrDispatch.careerOutLookDispatch(evnt);
        break;
      }
      case 4: {
        this.careersJrDispatch.careerEducationTrainingDispatch(evnt);
        break;
      }
      case 5: {
        this.careersJrDispatch.careerRelatedDispatch(evnt);
        break;
      }
    }
  }
}
