import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

/**CAUTION - To upgrade chart.js or annotation plugin
 * 
 * Make sure two package versions are compatible
 * 
 * currently, doughnut charts are used in
 * 1. Career Planning Home
 * 2. Career planning header
 * 3. Paying tab of school details
*/

Chart.register(...registerables);
Chart.register(annotationPlugin);
/**
 * This is a shared component to draw charts in the app to 
 * consolidate individual charts functions across the modules 
 * 
 * Currently, the component can draw 
 *    pie and doughnut chart.
 * 
 * 
 * Required parameters
 *  @Input() chartData: number[];
 
 Optional parameters
  @Input() chartColors: string[];
  @Input() chartType: string = '';
  @Input() chartLabels: string[];
  @Input() chartHeight: string;
  @Input() chartWidth: string;
  @Input() classList: string;
  @Input() cutoff: string = '';
  @Input() centerText: boolean = false; //this is for doughnut ONLY
  Notes: default chart height and width is 100px
 */
@Component({
  selector: 'app-pie-doughnut-chart',
  templateUrl: './pie-doughnut-chart.component.html',
  styleUrls: ['./pie-doughnut-chart.component.css']
})
export class PieDoughnutChartComponent implements OnInit {
  @Input() chartType: string = '';
  @Input() chartData: number[] = [];
  @Input() chartColors: string[];
  @Input() chartLabels: string[];

  @Input() chartHeight: string;
  @Input() chartWidth: string;
  @Input() classList: string;
  @Input() cutout: string;
  @Input() centerText: boolean = false;
  //hieght, width
  /** */

  pieDatasets: ChartConfiguration<'pie'>['data']['datasets'] = [];

  doughnutDatasets: ChartConfiguration<'doughnut'>['data']['datasets'];

  //chart options - pie chart  
  pieChartOptions: ChartConfiguration<'pie'>['options'] = {
    responsive: false,
    maintainAspectRatio: false,
    aspectRatio: 2.0,
    plugins: {
      legend: {
        display: false
      }
    }
  };

  //chart option - doughnut chart
  doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {};


  /**set default chart dimension */
  canvasHeight: string = '100px';
  canvasWidth: string = '100px';

  /**some modules may use classes */
  canvasClassList: string = '';

  constructor() {

  }

  ngOnInit(): void {
    //set default chart type to pie
    if (this.chartType === '' || !this.chartType) {
      this.chartType = 'pie'
    }

    if (this.chartHeight && this.chartHeight !== '') {
      this.canvasHeight = this.chartHeight

    }
    if (this.chartWidth && this.chartWidth !== '') {
      this.canvasWidth = this.chartWidth
    }

    if (this.chartType === 'doughnut') {
      this.doughnutDatasets = [
        {
          data: this.chartData,
          backgroundColor: this.chartColors
        }
      ];
      if (this.cutout && this.cutout !== '') {
        this.doughnutChartOptions['cutout'] = this.cutout;
      }
    }


    if (this.chartType === 'pie') {
      this.pieDatasets = [
        {
          data: this.chartData,
          backgroundColor: this.chartColors
        }
      ]
    }
    if (this.classList) {
      this.canvasClassList = this.classList;
    }

    this.doughnutChartOptions = {
      responsive: false,
      cutout: '60%',
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        annotation: {
          annotations: [
            {
              type: 'label',
              xValue: 2.5,
              yValue: 60,
              backgroundColor: '#fff',
              content: (this.centerText ? [`${this.calculatePlanCompletePercentage(this.chartData)}`] : ['']),
              font: {
                size: this.canvasWidth === '140px' ? 24 : 13,
                weight: 'bold'
              }
            },
          ],
        },
      }
    };

  }

  chartClicked() {
    return null;
  }

  chartHovered() {
    return null;
  }
  calculatePlanCompletePercentage(data: number[]): string {
    if (Array.isArray(data) && data.length >= 3) {
      let sum: number = 0;
      data.forEach(item => {
        sum += item;
      })
      return ((data[0] / sum) * 100).toFixed(0) + '%';
    }
  }
  /**
   * 
   * @param changes @Input() chartType: string = '';
    @Input() chartData: number[];
    @Input() chartColors: string[];
    @Input() chartLabels: string[];
    @Input() chartHeight: string;
    @Input() chartWidth: string;
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartData'] && this.chartType === 'pie') {
      this.pieDatasets = [
        {
          data: this.chartData,
          backgroundColor: this.chartColors
        }
      ]

    }
    if (changes['chartData'] && this.chartType === 'doughnut') {
      this.doughnutDatasets = [
        {
          data: this.chartData,
          backgroundColor: this.chartColors
        }
      ];

    }
    if (changes['chartData'] && this.chartType === 'doughnut') {
      this.doughnutChartOptions = {
        responsive: false,
        cutout: '60%',
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          annotation: {
            annotations: [
              {
                type: 'label',
                xValue: 2.5,
                yValue: 60,
                backgroundColor: '#FFF',
                content: (this.centerText ? [`${this.calculatePlanCompletePercentage(this.chartData)}`] : ['']),
                font: {
                  size: this.canvasWidth === '140px' ? 24 : 14,
                  weight: 'bold'
                }
              },
            ],
          },
        }
      };
    }
  }
  // ngAfterViewInit() {
  //   if (this.chartHeight && this.chartHeight !== '') {
  //     this.canvasHeight = this.chartHeight

  //   }
  //   if (this.chartWidth && this.chartWidth !== '') {
  //     this.canvasWidth = this.chartWidth
  //   }

  //   if (this.chartType === 'doughnut') {
  //     this.doughnutDatasets = [
  //       {
  //         data: this.chartData,
  //         backgroundColor: this.chartColors
  //       }
  //     ];
  //     console.log('this.doughnutDatasets:', this.doughnutDatasets)
  //     if (this.cutout && this.cutout !== '') {
  //       this.doughnutChartOptions['cutout'] = this.cutout;
  //     }
  //   }


  //   if (this.chartType === 'pie') {
  //     this.pieDatasets = [
  //       {
  //         data: this.chartData,
  //         backgroundColor: this.chartColors
  //       }
  //     ]
  //   }
  //   if (this.classList) {
  //     this.canvasClassList = this.classList;
  //   }

  // }

}
