import { Component, ViewChild, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { StorageService } from './shared/outer-services/storage.service';
import { routeConstants } from './shared/constants/app.constants';
import { Router, NavigationEnd } from '@angular/router';
import { getRouteConfig } from './route.config';
import { StoreSharedService } from '../app/shared/outer-services/store-shared.service';
import { ServerApi } from './shared/outer-services/app.apicall.service';
import { EventDispatchService } from './shared/outer-services/event-dispatch.service';
import { debounceTime, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import * as AccountTypeAction from './state-management/actions/account-Type.action';
import { AccountTypeModel } from './state-management/state/account-Type.state';
import { LtiLocationModel } from './state-management/state/lti-location.state';
import { Store } from '@ngrx/store';
import * as LtiActionType from './state-management/actions/lti-module.action';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DomainTracking } from './shared/modal/domainTracking';
import { AuthService } from './shared/auth/auth-jwt';
import { CheckAuthTokenService } from './shared/outer-services/check-auth-token.service';

declare let gtag: Function;
@Component({
  selector: 'PLP-app',
  template: `
    <ngbd-modal-snackbar></ngbd-modal-snackbar>
    <ngbd-modal-snackbar-red></ngbd-modal-snackbar-red>
    <ng-template ngbModalContainer></ng-template>
    <ngx-spinner size="medium" type="ball-spin-clockwise"></ngx-spinner>
    <!--<ng4-loading-spinner style="z-index:9999999999999;position: relative;"> </ng4-loading-spinner>-->
    <div id="wrapper-body">
      <a
        (click)="skipLink()"
        href
        onclick="return false;"
        class="sr-only sr-only-focusable btn btn-link "
        accesskey="k"
      >
        <span class="skip-maincontent btn-primary d-block">
          Skip to content
        </span>
      </a>
      <div id="main-wrapper-body">
        <div id="main-body" tabindex="-1" class="pb-3">
          <router-outlet></router-outlet>

          <static-footer class="footerheightlength"></static-footer>
        </div>
      </div>
    </div>
  `,
})
export class AppComponent implements OnInit {
  @ViewChild('main', { static: true }) main: ElementRef;
  routeSub = new Subscription();

  ga_trackingIDs: Array<DomainTracking> = new Array<DomainTracking>();

  constructor(
    private router: Router,
    private loginauth: ServerApi,
    private storageService: StorageService,
    private pugser: StoreSharedService,
    private renderer: Renderer2,
    private eventDispatcher: EventDispatchService,
    private accountStore: Store<AccountTypeModel>,
    private locatonStore: Store<LtiLocationModel>,
    private httpClient: HttpClient,
    private authService: CheckAuthTokenService
  ) {
    try {
      this.routeSub = router.events
        .pipe(debounceTime(300))
        .subscribe((event) => {
          // this.routeEvents();
          //  console.log('route changing')
          this.skipLink();
        });

      /** Route configuration creation based on framework JSON loaded on last Login  */
      const frameConfig = this.storageService.sessionStorageGet(
        'loginFrameworkConfiguration'
      );
      const authVal = this.storageService.sessionStorageGet('auth_key');
      const tokenInfo = this.getDecodedAccessToken(authVal); // decode token
      const accountType = tokenInfo.payload.accountType;
      const isZipLogin = tokenInfo.payload.isZipLogin;
      const zipType = tokenInfo.payload.siteType;
      const zipCity = tokenInfo.payload.city;
      const zipCode = tokenInfo.payload.zipCode;
      const additionalAcctId = tokenInfo.payload.additionalAccountID;
      const initialLogin =
        this.storageService.sessionStorageGet('initialLogin');
      const logoutUrl = this.storageService.sessionStorageGet('LogoutURL');
      const startLocation =
        this.storageService.sessionStorageGet('startLocation');

      if (authVal && this.authService.isTokenExpired()) {
        localStorage.clear();
        this.storageService.sessionStorageClear();
        window.location.href = 'login';
      }

      if (!authVal || authService.isTokenExpired()) {
        localStorage.clear();
        this.storageService.sessionStorageClear();
        window.location.href = 'login';
      } else if (initialLogin == 'true') {
        // @todo this should be moved to a more appropriate place like a reducer action.
        // The session and local storage should be cleared each time the application is reached through
        // a hard browser navigation, otherwise some state from a previous session may survive and cause
        // unwanted side effects.  One such side effect was the content section of the page loading no
        // content.
        localStorage.clear();
        this.storageService.sessionStorageClear();
        if (startLocation) {
          this.locatonStore.dispatch(
            new LtiActionType.SetLtiLocationAction(startLocation)
          );
        }
        this.storageService.sessionStorageSet('auth_key', authVal);
        if (logoutUrl) {
          this.storageService.sessionStorageSet('LogoutURL', logoutUrl);
        }
        //add zipLogin flag to session storage
        if (isZipLogin) {
          this.storageService.sessionStorageSet('isZipLogin', isZipLogin);
          this.storageService.sessionStorageSet(
            'additionalAcctId',
            additionalAcctId
          );
          this.storageService.sessionStorageSet('siteType', zipType);
          this.storageService.sessionStorageSet('city', zipCity);
          this.storageService.sessionStorageSet('zipCode', zipCode);
        }

        this.accountStore.dispatch(
          new AccountTypeAction.SetAccountTypeAction(accountType)
        );
        this.pugser.pugLogin();
      }
    } catch (e) {
      console.log('app component exception:' + e.message);
    }
  }
  skipLink() {
    let evnt = document.createEvent('CustomEvent');
    this.eventDispatcher.dispatchStaticContent(evnt);
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  /**
   * Google Analytics
   *  1. Add State domain as property in Google Analytics
   *  2. adding property to domain-trackingIds.json file(assests/json-config/)
   */

  ngOnInit(): void {
    /* add google analytics if GoogleAnalytics in enrironment.ts is true  */
    if (environment.GoogleAnalytics && environment.production) {
      this.httpClient
        .get('./assets/json-config/domain-trackingIds.json')
        .subscribe((data: DomainTracking[]) => {
          this.ga_trackingIDs = data;

          /**testing code */
          // const { hostname } = new URL("http://qa.cis360.org/");
          // console.log("hostname: ", hostname);
          // ====================================
          const { hostname } = new URL(window.location.href);

          //set default google analytics tracking Id - cis360.org
          let trackingID = '5B57PZBMYX';

          //retrive Google Analytics tracking ID
          if (hostname && hostname.length >= 0) {
            const trackingId = this.ga_trackingIDs.find(
              (x) => x.hostName === hostname.toLowerCase()
            );

            if (trackingId) {
              trackingID = trackingId.trackingId;
            }
          }

          this.buildDynamicGoogleAnalytics(trackingID);
          this.setUpAnalytics(trackingID);
        });
    }
  }
  /* dynamically build tracking code */
  buildDynamicGoogleAnalytics(tracking_id) {
    if (environment.GoogleAnalytics && tracking_id) {
      // google analytics manager script
      const gaManagerScript = document.createElement('script');
      gaManagerScript.async = true;
      gaManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${tracking_id}`;
      document.head.appendChild(gaManagerScript);

      // google analytics script
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
    `;
      document.head.appendChild(gaScript);
    }
  }

  setUpAnalytics(tracking_id: string) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', tracking_id, {
          page_path: event.urlAfterRedirects,
        });
      });
  }
}

