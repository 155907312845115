import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import {
  JobInterviewIntro,
  JobInterviewPanel,
  JobInterviewExtraItem,
} from '../models/JobInterview.model';
import { EventDispatchService } from '../../../shared/outer-services/event-dispatch.service';
import { Subscription } from 'rxjs-compat/Subscription';
import { Observable, of } from 'rxjs';
import { StorageService } from '../../../shared/outer-services/storage.service';

/**animation open div */
import {
  trigger, state, style, transition,
  animate
} from '@angular/animations';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @ViewChild('videoplayer', { static: false }) videoplayer: ElementRef;
  @ViewChild('interviewHeaderFocus', { static: false })
  interviewHeaderFocus: ElementRef;

  openPanel: string = 'none';
  educationExpand: string = 'none';
  eventSubscription = new Subscription();
  //get language setting
  langVal: string = StorageService.staticSessionStorageGet('langset')
    ? StorageService.staticSessionStorageGet('langset')
    : 'en';

  //panel data
  jobInterviewPanelData: Array<JobInterviewPanel>;
  jobInterviewPanel$: Observable<JobInterviewPanel[]>;
  jobInterviewPanelLangSet: JobInterviewPanel[];

  extraItems: JobInterviewExtraItem[];

  //intro data
  jobInterviewIntroData: JobInterviewIntro[];
  jobInterviewIntro$: Observable<JobInterviewIntro[]>;
  jobInterviewIntroLangSet: JobInterviewIntro;

  jobInterviewVideoUrl: string = '';
  jobInterviewCaptionUrl: string = '';
  jobInterviewTitle: string = '';
  hasError: boolean = false;

  constructor(
    /**font icon url  */
    @Inject('FONT_ICONS_URL') public fontIcons: string,
    @Inject('IC_VIDEO_URL') public videoUrl: string,
    @Inject('LANDING_IMAGE_URL') public landingImageUrl: string,
    private eventService: EventDispatchService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    /**TO DO:
     * add api to fetch those data
     */

    this.extraItems = new Array<JobInterviewExtraItem>({
      lang: 'en', //English or Spanish
      videoUrl: this.videoUrl + 'JS/interviewTypes.mp4',
      captionUrl: null,
      description: 'Learn about different types of interviews.',
      title: 'Interview Types',
      pdfLink: 'https://materials.intocareers.org/JS/Types_Bullet_Points.pdf'
    },
      {
        lang: 'en', //English or Spanish
        videoUrl: this.videoUrl + 'JS/HowToAceTheInterview.mp4',
        captionUrl: null,
        description: 'How to Ace the Interview.',
        title: 'How to Ace the Interview',
        pdfLink: ''
      },
    );

    this.jobInterviewPanelData = new Array<JobInterviewPanel>(
      {
        lang: 'en',
        tabId: 'JIPrepare',
        title: 'Prepare',
        videoUrl: this.videoUrl + 'JS/prepare.mp4',
        captionUrl: '',
        notes: null,
        description:
          'Learn what info you need to gather for your interview, get organized and prepare to make a good impression.',
        tabOrder: 0,
        tabColorClass: 'cardcolor-55',
        iconUrl: this.fontIcons + 'prepare.svg',
        landingUrl: this.landingImageUrl,
        subItems: new Array<JobInterviewExtraItem>(),
        pdfLink: 'https://materials.intocareers.org/JS/Prepare_Bullet_Points.pdf'
      },
      {
        lang: 'en',
        tabId: 'JIPractice',
        title: 'Practice',
        videoUrl: this.videoUrl + 'JS/Practice.mp4',
        captionUrl: null,
        notes: null,
        description:
          'Be ready to answer interview questions thoughtfully and learn how to talk about your strengths and weaknesses in a positive light.',
        tabOrder: 1,
        tabColorClass: 'cardcolor-56',
        iconUrl: this.fontIcons + 'practice.svg',
        landingUrl: this.landingImageUrl,
        subItems: new Array<JobInterviewExtraItem>(),
        pdfLink: 'https://materials.intocareers.org/JS/Practice_Bullet_Points.pdf'
      },
      {
        lang: 'en',
        tabId: 'JIInterview',
        title: 'The Interview',
        videoUrl: this.videoUrl + 'JS/interview.mp4',
        captionUrl: null,
        notes: null,
        description:
          'Good Communication is essential for a successful interview. Learn how to interact with an interviewer and leave a good impression.',
        tabOrder: 2,
        tabColorClass: 'cardcolor-57',
        iconUrl: this.fontIcons + 'interview.svg',
        landingUrl: this.landingImageUrl,
        subItems: this.extraItems,
        pdfLink: 'https://materials.intocareers.org/JS/Interview_Bullet_Points.pdf'
      },
      {
        lang: 'en',
        tabId: 'JIFollowup',
        title: 'Follow up',
        videoUrl: this.videoUrl + 'JS/followup.mp4',
        captionUrl: null,
        notes: null,
        description:
          'You aren’t done after the interview. There are a few more things you can do to build on the good impression you made.',
        tabOrder: 3,
        tabColorClass: 'cardcolor-58',
        iconUrl: this.fontIcons + 'follow up.svg',
        landingUrl: this.landingImageUrl,
        subItems: new Array<JobInterviewExtraItem>(),
        pdfLink: 'https://materials.intocareers.org/JS/FollowUp_Bullet_Points.pdf'
      },
      {
        lang: 'es',
        tabId: 'JIPrepare',
        title: 'Preparar',
        videoUrl: this.videoUrl + 'JS/prepare.mp4',
        notes: null,
        description:
          'Aprenda qué información necesita recopilar para su entrevista, organícese y prepárese para causar una buena impresión. Conozca qué información necesita recopilar para su entrevista, organícese y prepárese para causar una buena impresión.',
        tabOrder: 0,
        tabColorClass: 'cardcolor-55',
        iconUrl: this.fontIcons + 'prepare.svg',
        landingUrl: this.landingImageUrl,
        subItems: new Array<JobInterviewExtraItem>(),
        pdfLink: 'https://materials.intocareers.org/JS/Prepare_Bullet_Points.pdf'
      },
      {
        lang: 'es',
        tabId: 'JIPractice',
        title: 'Práctica',
        videoUrl: this.videoUrl + 'JS/Practice.mp4',
        captionUrl: null,
        notes: null,
        description:
          'Esté preparado para responder las preguntas de la entrevista cuidadosamente y aprenda a hablar sobre sus fortalezas y debilidades de manera positiva.',
        tabOrder: 1,
        tabColorClass: 'cardcolor-56',
        iconUrl: this.fontIcons + 'practice.svg',
        landingUrl: this.landingImageUrl,
        subItems: new Array<JobInterviewExtraItem>(),
        pdfLink: 'https://materials.intocareers.org/JS/Practice_Bullet_Points.pdf'
      },
      {
        lang: 'es',
        tabId: 'JIInterview',
        title: 'La Entrevista',
        videoUrl: this.videoUrl + 'JS/interview.mp4',
        captionUrl: null,
        notes: null,
        description:
          'La buena comunicación es esencial para una entrevista exitosa. Aprende a interactuar con un entrevistador y deja una buena impresión.',
        tabOrder: 2,
        tabColorClass: 'cardcolor-57',
        iconUrl: this.fontIcons + 'interview.svg',
        landingUrl: this.landingImageUrl,
        subItems: new Array<JobInterviewExtraItem>(),
        pdfLink: 'https://materials.intocareers.org/JS/Interview_Bullet_Points.pdf'
      },
      {
        lang: 'es',
        tabId: 'JIFollowup',
        title: 'Hacer un seguimiento',
        videoUrl: this.videoUrl + 'JS/followup.mp4',
        captionUrl: null,
        notes: null,
        description:
          'No has terminado después de la entrevista. Hay algunas cosas más que puedes hacer para construir sobre la buena impresión que hiciste.',
        tabOrder: 3,
        tabColorClass: 'cardcolor-58',
        iconUrl: this.fontIcons + 'follow up.svg',
        landingUrl: this.landingImageUrl,
        subItems: new Array<JobInterviewExtraItem>(),
        pdfLink: 'https://materials.intocareers.org/JS/FollowUp_Bullet_Points.pdf'
      }
    );

    this.jobInterviewIntroData = new Array<JobInterviewIntro>(
      {
        lang: 'en',
        header: 'Job Interviews',
        introduction:
          "If you've landed the job interview, congratulations. Now you need to prepare yourself to make the interview a success and make a good impression. To get started watch the videos.",
      },
      {
        lang: 'es',
        header: 'Entrevistas de Trabajo',
        introduction:
          'Una entrevista de trabajo exitosa requiere mucho más que simplemente ir a una cita con un posible empleador. Hay muchas cosas que puede hacer antes, durante y después de la entrevista para que sea más probable que obtenga el trabajo. En este vídeo aprenderás a prepararte para el gran día.',
      }
    );
    if (this.interviewHeaderFocus) {
      this.interviewHeaderFocus.nativeElement.setAttribute('tabindex', '0');
      this.interviewHeaderFocus.nativeElement.focus();
    }
    //change languages
    this.switchLanguage();
  }

  /**To Do: Switch to using a translation module translation.config.module */
  switchLanguage() {
    this.jobInterviewIntro$ = of(
      this.jobInterviewIntroData.filter((x) => x.lang == this.langVal)
    );
    this.jobInterviewIntro$.subscribe(
      (intro: JobInterviewIntro[]) => {
        this.jobInterviewIntroLangSet = intro.shift(); //get first element
        if (!this.jobInterviewIntroLangSet) {
          this.hasError = true;
        }
      },
      (err: any) => {
        console.error(err);
        this.hasError = true;
      }
    );

    //fet observable
    this.jobInterviewPanel$ = of(
      this.jobInterviewPanelData
        .filter((x) => x.lang == this.langVal)
        .sort((a, b) => a.tabOrder - b.tabOrder) //panel order
    );

    this.jobInterviewPanel$.subscribe((response) => {
      (this.jobInterviewPanelLangSet = response),
        (err: any) => {
          this.hasError = true;
          console.error(err);
        };
    });
  }

  /**function to open/close content panels */
  openCloseAction(tabId?: string) {
    if (this.educationExpand === tabId) {
      this.educationExpand = 'none';
    }
    else {
      this.educationExpand = tabId;
    }
    this.videoplayer.nativeElement.pause();

  }

  public stopVideo() {
    this.videoplayer.nativeElement.load();
    this.videoplayer.nativeElement.pause();
  }

  public playOrSwitchVideo(sectionName: string) {
    if (!sectionName || sectionName == '') {
      return;
    }
    //stop previous video
    this.stopVideo();

    const { videoUrl, captionUrl, title } = this.jobInterviewPanelLangSet
      .filter((x) => x.tabId == sectionName && x.lang == this.langVal)
      .shift();

    this.jobInterviewVideoUrl = videoUrl;
    this.jobInterviewCaptionUrl = captionUrl;
    this.jobInterviewTitle = title;
  }

  public playOrSwitchItemVideo(itemVideoUrl: string, captionUrl: string, videoTitle: string = '') {
    if (!itemVideoUrl || itemVideoUrl == '') {
      return;
    }
    //stop previous video
    this.stopVideo();

    this.jobInterviewVideoUrl = itemVideoUrl;
    this.jobInterviewCaptionUrl = captionUrl;
    this.jobInterviewTitle = videoTitle;
  }
  //listening to language changes after view is fully loaded
  ngAfterViewInit() {
    this.eventSubscription = this.eventService.listen().subscribe((e) => {
      /** After event listen it will check whether user want to save partially or completely */
      if (e.type == 'languageChanged') {
        this.langVal = this.storageService.sessionStorageGet('langset')
          ? this.storageService.sessionStorageGet('langset')
          : 'en';
        //update view content
        this.switchLanguage();
      }
    });
  }
  //release rxjs objects
  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }
}
