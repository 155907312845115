/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

/**Services **/
import html2pdf from 'html2pdf.js';
import {
  CareersJr_Buttons_Reducer_Text_State,
  CareersJr_EmpOutlook_Wages_State,
  CareersJr_Settings_Tab_Text_State,
} from '../../careersJr-state-management/careersJr-state/careersJr-state';
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'at-a-glance-careersJr',
  templateUrl: './at-a-glance-careersJr.layout.html',
})
export class AtAGlanceCareersJrComponent implements OnInit, OnDestroy {
  occCareerStore; /** Is a variable that is used to store data coming from reducer */
  showClusterBox = true; // Checks whether to show cluster box or not
  eduIcon = [
    'icon-education-level-1',
    'icon-education-level-2',
    'icon-education-level-3',
    'icon-education-level-4',
    'icon-education-level-5',
  ];
  settingsText; /** Is a variable that is used to store data coming from reducer */
  occCareerText; /** Is a variable that is used to store data coming from reducer */
  settingsTextTab;
  occName;
  printHidden = false;

  // printpage= true;
  @ViewChild('atAGlancePrintPage', { static: true })
  atAGlancePrintPage: ElementRef;

  @Input('logo') logo;
  @Input('careerTitle') careerTitle;
  @Input('tabTitle') tabTitle;
  @Input('frameWorkdata') frameWorkObj;
  public doughnutChartLabels = ['Completed', 'Started', 'Incomplete'];
  public doughnutChartData: number[] = [8, 52, 22, 8];
  public doughnutChartType = 'doughnut';
  optionsChart = {
    tooltips: { enabled: false },
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
    // maintainAspectRatio: false
    // aspectRatio: 2.5
  };
  public doughnutDataset: Array<any> = [
    {
      // grey
      backgroundColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      borderColor: ['#0d8dbe', '#00346d', '#888888', '#0d8dbe'],
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];
  onLoadFirst = true;
  moreDataDailog: boolean;
  compActive = true;
  constructor(
    private router: Router,
    private occSetstore: Store<CareersJr_Settings_Tab_Text_State>,
    private occDispatch: CareersJrDispatchService,
    private activatedRoute: ActivatedRoute,
    private utils: Utilities,
    private store: Store<any>,
    private occstore: Store<CareersJr_Buttons_Reducer_Text_State>,
    private OCCPageStateStore: Store<CareersJr_EmpOutlook_Wages_State>,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('FONT_ICONS_URL') public fontIcons: string
  ) {
    this.occDispatch
      .careerAtglanceListen()
      .pipe(takeWhile(() => this.compActive))
      .subscribe((e) => {
        this.utils.showLoading();
        this.careerPrint();
      });

    // Get data from reducer to display buttons text
    this.occCareerText = this.occstore.select('CareersJr_Buttons_Reducer_Text');
    // Get data from reducer to show tab names and to check sections are available or not etc.,
    this.occSetstore
      .select('CareersJr_Settings_Tab_Text')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((v) => {
        this.settingsText = v;
        // console.log('settingsText',this.settingsText)
        if (
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != null &&
          this.settingsText.CareersJr_Settings_Tab_Text.tabs != undefined
        ) {
          const ref = this;
          this.settingsText.CareersJr_Settings_Tab_Text.tabs.forEach(
            function (obj, inx) {
              if (obj['tabId'] == 0) {
                ref.settingsTextTab = obj;
                // console.log('settingsTextTab',this.settingsTextTab)
              }
            }.bind(this)
          );
          this.onLoadFirst = false;
        }
      });
    // Get data from reducer to display in cards
    this.OCCPageStateStore.select('CareersJr_Detail_AtGlance_Selector')
      .pipe(takeWhile(() => this.compActive))
      .subscribe((v) => {
        this.occCareerStore = v;

        if (this.occCareerStore.TopPreparation) {
          this.moreDataDailog = this.occCareerStore.TopPreparation.filter(
            (f) => f.type === 'ul'
          ).some((s) => s.text.indexOf('[More]') != -1);

        }
        if (
          this.occCareerStore.HotJob !== undefined &&
          this.occCareerStore.STEM !== undefined
        ) {
          if (
            this.occCareerStore.HotJob.rating ||
            this.occCareerStore.STEM.rating
          ) {
            this.showClusterBox = false;
          } else {
            this.showClusterBox = true;
          }
        }
      });
  }
  ngOnInit() {
    const val = JSON.parse(StorageService.staticSessionStorageGet('Reducer_OccText'));
    if (
      val &&
      val.Occ_Buttons_Reducer_Text &&
      val.Occ_Buttons_Reducer_Text.common != undefined
    ) {
      this.occCareerText = val;
    }
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.compActive))
      .subscribe((params) => {
        this.occName = params['occname'];
      });
  }
  printchange(event) {
    this.printHidden = false;
  }
  ngOnDestroy() {
    //unsubscribe all the subscritions
    this.compActive = false;
  }

  //When user click on card respective events are emmited
  callPage(page: string) {
    if (page == 'wage') {
      const evt = new Event('callWagePage', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    } else if (page == 'outlook') {
      const evt = new Event('callOutlookPage', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    } else if (page == 'edu') {
      const evt = new Event('calleduPage', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    } else if (page == 'job_Setting') {
      const evt = new Event('job_Setting', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    } else if (page == 'job_Task') {
      const evt = new Event('job_Task', {
        bubbles: true,
        cancelable: false,
      });
      this.occDispatch.dispatch(evt);
    }
  }
  async careerPrint() {
    const element = this.atAGlancePrintPage.nativeElement;
    const opt = {
      filename: 'Career Jr - At a Glance.pdf',
      margin: [0.2, 0, 0.2, 0],
      image: { type: 'svg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true, useCORS: true },
      jsPDF: { unit: 'mm', format: 'A4', compress: true },
      pagebreak: { mode: ['avoid-all', 'css'] },
    };
    // New Promise-based usage:
    await html2pdf(element, opt);
    this.utils.hideLoading();
  }
  //Redirect to cluster page
  workplaceRedirect(id, name, icon, color) {
    this.router.navigate(['../careersJrClusterHeader'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        clusId: id,
        clusName: name,
        clusIcon: icon,
        clusColor: color,
      },
    });
  }
}
