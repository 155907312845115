
/** Angular imports */
import { importProvidersFrom, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Custom imports
import {
  CustomDate,
  pagesFilter,
  BoldPipe,
  EQPipe,
  BestCareerCluster,
  customDateSafariSafe,
  TransformMarkdownPipe,
  TransformMarkdownHiperPipe,
} from './pipes/customPipes';
import {
  TextIteratorPipe,
  PrintWeblinkPipe,
  PrintlinkPipe,
  StatesName,
  alphabetized,
} from '../modules/careers-details/occ-details/occ-shared/text-custompipe';
import {
  TextIteratorPipeJr,
  PrintCustomlinkPipe,
  PrintWeblinkPipeJr,
  ConstructText,
} from '../modules/careers-details/careersJr/careersJr-shared/text-custompipe';
import { HiperLinkDisplay } from '../modules/education-details/schools/schools-services/schools-hiper-link';
import { AudioButtonComponent } from './audio/audio.component';
import { AudioService } from './outer-services/audio.service';
import { PieDoughnutChartComponent } from './pie-doughnut-chart/pie-doughnut-chart.component';
import { NgChartsModule } from 'ng2-charts';
@NgModule({
  imports: [CommonModule, NgChartsModule],
  declarations: [
    AudioButtonComponent,
    AudioService,
    CustomDate,
    pagesFilter,
    BoldPipe,
    TextIteratorPipe,
    TextIteratorPipeJr,
    PrintCustomlinkPipe,
    PrintWeblinkPipeJr,
    ConstructText,
    HiperLinkDisplay,
    PrintWeblinkPipe,
    EQPipe,
    customDateSafariSafe,
    PrintlinkPipe,
    StatesName,
    BestCareerCluster,
    alphabetized,
    TransformMarkdownPipe,
    TransformMarkdownHiperPipe,
    PieDoughnutChartComponent
  ],
  exports: [
    AudioButtonComponent,
    AudioService,
    CustomDate,
    pagesFilter,
    BoldPipe,
    CommonModule,
    FormsModule,
    customDateSafariSafe,
    //  HttpClientModule,
    TextIteratorPipe,
    HiperLinkDisplay,
    PrintWeblinkPipe,
    EQPipe,
    BestCareerCluster,
    TextIteratorPipeJr,
    PrintCustomlinkPipe,
    PrintWeblinkPipeJr,
    ConstructText,
    PrintlinkPipe,
    StatesName,
    alphabetized,
    TransformMarkdownPipe,
    TransformMarkdownHiperPipe,
    PieDoughnutChartComponent
  ],
})
export class SharedModule {}
